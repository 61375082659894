import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HyperLinkDTO} from '../entities/HyperLinkDTO';
import {catchError} from 'rxjs/operators';
import {GameSeriesDTO} from '../entities/GameSeriesDTO';

@Injectable({
	providedIn: 'root'
})
export class GameSeriesService {
	private serverUrl = 'files/games-series/';
	private fileExtension = '.json';

	constructor(private http: HttpClient) {
	}

	public getGamesSeriesData(filename: string): Observable<GameSeriesDTO> {
		return this.http.get<GameSeriesDTO>(this.serverUrl + filename + this.fileExtension).pipe(
			catchError(this.handleError('getGamesSeriesData', null))
		);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {

			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
