<div class="container-fluid downloads">
	<div class="container">
		<div class="row mb-4">
			<div class="col-12">
				<h4>Letölthető fájlok</h4>
				<div class="list-group">
					<a href="files/mods/mods.rar" class="list-group-item list-group-item-action">Farming Simulator 2015 - Aktuálisan használt modok</a>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<h4>Modok</h4>
				<div class="list-group">
					<a *ngFor="let mod of mods" href="files/mods/{{ mod.url }}" class="list-group-item list-group-item-action">{{ mod.title }}</a>
				</div>
			</div>
			<div class="col-md-6 mt-4 mt-md-0">
				<h4>Mentések</h4>
				<div class="list-group">
					<a *ngFor="let savegame of savegames" href="files/savegame/{{ savegame.url }}" class="list-group-item list-group-item-action">{{ savegame.title }}</a>
				</div>
			</div>
		</div>
	</div>
</div>
