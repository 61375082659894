<app-header></app-header>

<app-games-series
	data-source-src="fs22"
	background-class="fs22"
	game-logo-src="fs_2022.png">
</app-games-series>
<app-games-series
	data-source-src="fs19"
	background-class="fs19"
	game-logo-src="fs_2019.png">
</app-games-series>
<app-games-series
	data-source-src="fs17"
	background-class="fs17"
	game-logo-src="fs_2017.png">
</app-games-series>
<app-games-series
	data-source-src="fs15"
	background-class="fs15"
	game-logo-src="fs_2015.png">
</app-games-series>

<app-downloads></app-downloads>

<app-footer></app-footer>
