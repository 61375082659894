<div class="container-fluid {{ backgroundClass }}">
	<div class="container">
		<div class="row">
			<div class="col-md-5 mb-5 mb-md-0">
				<img class="img-fluid" src="assets/gamelogos/{{ gameLogo }}">
			</div>
			<div class="col-md-1"></div>
			<div class="col-md-6">
				<a href="https://www.youtube.com/watch?v={{ gameSeriesData.mainVideo + (gameSeriesData.playList?.length ? '&list=' + gameSeriesData.playList : '') }}">
					<div class="video">
						<img class="img-fluid" src="https://i.ytimg.com/vi/{{ gameSeriesData.mainVideo }}/maxresdefault.jpg">
						<i class="{{ gameSeriesData.playList?.length ? 'fas fa-list-ul' : 'fab fa-youtube' }}"></i>
					</div>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4" *ngIf="gameSeriesData.placeHolder"></div>
			<div class="col-md-4" *ngFor="let video of gameSeriesData.videos">
				<a href="https://www.youtube.com/watch?v={{ video }}}">
					<div class="video">
						<img class="img-fluid" src="https://i.ytimg.com/vi/{{ video }}/maxresdefault.jpg">
						<i class="fab fa-youtube"></i>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
