import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {DownloadsComponent} from './downloads/downloads.component';
import {GamesSeriesComponent} from './games-series/games.series.component';

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		NgbModule
	],
	declarations: [
		AppComponent,
		FooterComponent,
		HeaderComponent,
		DownloadsComponent,
		GamesSeriesComponent
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
