import {Component, Input, OnInit} from '@angular/core';
import {GameSeriesDTO} from '../../entities/GameSeriesDTO';
import {GameSeriesService} from '../../services/game-series.service';

@Component({
	selector: 'app-games-series',
	templateUrl: './games.series.component.html',
	styleUrls: ['./games.series.component.css', './games.series.background.css']
})
export class GamesSeriesComponent implements OnInit {

	@Input('data-source-src')
	private readonly filename;
	@Input('background-class')
	public readonly backgroundClass;
	@Input('game-logo-src')
	public readonly gameLogo;

	public gameSeriesData: GameSeriesDTO = new GameSeriesDTO();

	constructor(
		private gameSeriesService: GameSeriesService
	) {
	}

	ngOnInit(): void {
		this.gameSeriesService.getGamesSeriesData(this.filename).subscribe(result => this.gameSeriesData = result);
	}

}
