
<div class="container-fluid header">
	<div class="container">
		<div class="row">
			<div class="col-md-6 text-center text-md-left mb-4 mb-md-0">
				<div class="d-inline-block">
					<div class="profile-image">
						<img src="assets/images/profile.png">
					</div>
					<div class="profile-name">
						Ruszinkó<span>Marcell</span>
					</div>
				</div>
			</div>
			<div class="col-md-6 text-center text-md-right social-site">
				<a href="https://www.youtube.com/channel/UCAJ1EOylwc6b71R8K_FXANQ" target="_blank">
					<i class="fab fa-youtube"></i>
				</a>
				<a href="https://www.twitch.tv/rmarcell96" target="_blank">
					<i class="fab fa-twitch"></i>
				</a>
				<a href="https://www.facebook.com/RMarcell96" target="_blank">
					<i class="fab fa-facebook-square"></i>
				</a>
			</div>
		</div>
	</div>
</div>
