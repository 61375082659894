import {Component, OnInit} from '@angular/core';
import {SaveFilesServiceService} from '../../services/save-files-service.service';
import {ModsServiceService} from '../../services/mods-service.service';
import {HyperLinkDTO} from '../../entities/HyperLinkDTO';

@Component({
	selector: 'app-downloads',
	templateUrl: './downloads.component.html',
	styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {
	private readonly filename = 'fs15';

	public mods: HyperLinkDTO[];
	public savegames: HyperLinkDTO[];

	constructor(
		private saveFilesServiceService: SaveFilesServiceService,
		private modsServiceService: ModsServiceService
	) {
	}

	ngOnInit(): void {
		this.saveFilesServiceService.getSaveFiles(this.filename).subscribe(value => this.savegames = value);
		this.modsServiceService.getMods(this.filename).subscribe(value => this.mods = value);
	}

}
